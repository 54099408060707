import React from 'react';
import { lifecycle } from 'recompose';

const IndexPage = () => {
  return <div />;
};

export default lifecycle({
  componentDidMount() {
    if (typeof window !== 'undefined') {
      let locale = localStorage.getItem('locale');

      if (!locale) {
        locale = 'us';
      }
      window.location.pathname = `/${locale}/`;
    }
  },
})(IndexPage);
